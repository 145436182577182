.ExistingLotSelector_LotList {
  list-style: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.ExistingLotSelector_LotList_LotListItem span:first-child {
  text-overflow: ellipsis;
  overflow: hidden;
}
