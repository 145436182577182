.EntitySelector_EntityList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.EntitySelector_EntityList_header {
  padding: var(--gap-small);
  padding-top: 0;
  padding-bottom: calc(var(--gap-small) / 2);
}

.EntitySelector_EntityList_EntityListItem span:first-child {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
