.InputWrapper {
  display: flex;
  flex-direction: column;
  padding: calc(var(--gap-small) * 0.75);
}

.InputWrapper label {
  font-family: Narin;
  font-size: 12px;
  line-height: 150%;
}

.InputWrapper input {
  background-color: transparent;
  border: none;
  margin-top: 4px;
  font-family: Founders Grotesk;
  font-size: 18px;
  font-weight: 500;
}

.InputWrapper input:focus {
  outline: none;
}

.dark .InputWrapper {
  border: 1px solid var(--color-white);
}

.dark .InputWrapper label {
  color: var(--color-blue-flats);
}

.dark .InputWrapper input {
  color: var(--color-white);
}

.light .InputWrapper {
  border: 1px solid var(--color-blue-offshore);
}

.light .InputWrapper label {
  color: var(--color-blue-offshore);
}

.light .InputWrapper input {
  color: var(--color-blue-offshore);
}
