.ImageList {
  width: 100%;
}

.ImageListItem {
  position: relative;
}

.ImageListItem_button-container {
  flex-shrink: 0;
}

.ImageListItem img {
  flex: 1 1 auto;
  max-width: 100%;
  border-radius: 10px;
}

.ImageListItem_actions {
  position: absolute;
  bottom: var(--gap-medium);
  left: var(--gap-medium);
  right: var(--gap-medium);
}
