.Card {
  background-color: var(--color-white);
  box-shadow: 0px 2px 8px rgba(3, 35, 65, 0.2);
}

.LinkCard {
  cursor: pointer;
  text-decoration: none;
  transition: background-color var(--transition-duration) ease;
}

.LinkCard:link,
.LinkCard:visited {
  color: var(--color-blue-offshore);
}

.LinkCard:active,
.LinkCard:hover {
  background-color: var(--color-white-hover);
}

.LinkCard:active,
.LinkCard:focus {
  outline: 1px solid var(--color-blue-inshore);
}

.LinkCard_children-container {
  flex: 1 1 auto;
  overflow: hidden;
}
