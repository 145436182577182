@font-face {
  font-family: 'Founders Grotesk';
  src: url('/public/assets/fonts/FoundersGrotesk-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Founders Grotesk';
  src: url('/public/assets/fonts/FoundersGrotesk-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Narin';
  src: url('/public/assets/fonts/Narin-Black.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Narin';
  src: url('/public/assets/fonts/Narin-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
