body,
#root {
  height: 100vh;
  width: 100vw;
}

#root {
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --gap-small: 12px;
  --gap-medium: calc(var(--gap-small) * 2);
  --gap-large: calc(var(--gap-small) * 3);
  --color-primary: #ffffff;
  --color-secondary: #1e3261;
  --color-blue-inshore: #235aa7;
  --color-blue-offshore: #1e3261;
  --color-blue-flats: #6bcbc9;
  --color-coral-red: #f26969;
  --color-white: #ffffff;
  --color-white-hover: #f2f2f2;
  --transition-duration: 0.2s;
}
