@import './fonts.css';

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.density-compact {
  --gap: var(--gap-small);
}

.font-size-small {
  font-size: 0.85em;
}

.form {
  display: flex;
  flex-direction: column;
  gap: var(--gap-medium);
}

.gray-bg {
  background-color: #f5f6f8;
}

.grow {
  flex-grow: 1;
}

.white-bg {
  background-color: #ffffff;
}

.horizontal {
  display: flex;
  flex-direction: row;
}

.no-shrink {
  flex-shrink: 0;
}

.page {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, rgba(0, 22, 42, 0.44) 31.75%, rgba(0, 53, 102, 0.44) 100%),
    url('/public/assets/images/OW-portal-background.jpg') no-repeat top center;
  background-size: cover;
}

.pad-content {
  display: flex;
  padding: var(--gap, var(--gap-medium));
}

.disable-top-padding {
  padding-top: 0;
}

.space-content {
  gap: var(--gap, var(--gap-medium));
}

.vertical {
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3 {
  font-family: Narin;
  font-weight: 500;
  /** Add a negative margin so that the text baseline is roughly the bottom of the element */
  margin: 0 0 -0.4em 0;
}

h1.title {
  font-family: 'Founders Grotesk';
  font-size: 36px;
  line-height: 130%;
}

span,
p {
  font-family: 'Founders Grotesk';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.12em;
  margin: 0;
}

p + p {
  margin-top: var(--gap-small);
}

p.font-small,
span.font-small {
  font-size: 0.85em;
}

.dark h1,
.dark h2,
.dark h3,
.dark p {
  color: var(--color-white);
}

.light h1,
.light h2,
.light h3,
.light p {
  color: var(--color-blue-inshore);
}
