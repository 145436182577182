.LotAdjustmentCard span {
  margin-top: 6px;
  white-space: nowrap;
}

.LotAdjustmentCard span:first-child {
  overflow: hidden;
  flex-shrink: 1;
  text-overflow: ellipsis;
}
