.Button {
  color: var(--color-foreground, var(--color-white));
  cursor: pointer;
  background-color: var(--color-background, var(--color-blue-offshore));
  border: none;
  padding: 0.5rem;
  font-family: Narin;
  font-size: 0.8rem;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  width: fit-content;

  border-radius: 100px;
  padding: calc(var(--gap-small) + 4px) var(--gap-large) calc(var(--gap-small));
  letter-spacing: 0.12em;
  transition: background-color var(--transition-duration) ease-in-out;
}

.light .Button {
  --color-background: var(--color-blue-offshore);
  --color-foreground: var(--color-white);
}

.Button.creative {
  --color-background: var(--color-blue-flats);
  --color-foreground: var(--color-white);
}

.Button.destructive {
  --color-background: var(--color-coral-red);
  --color-foreground: var(--color-white);
}

.Button.secondary {
  --color-foreground: var(--color-blue-offshore);
  background-color: transparent;
  box-shadow: 0 0 0 2px var(--color-foreground);
}

.Button:disabled {
  cursor: not-allowed;
}

.Button.blue-flats {
  background-color: var(--color-blue-flats);
  color: var(--color-blue-offshore);
}

.Button.blue-flats:hover,
.Button.blue-flats:focus {
  background-color: #4fa2a0;
}

.Button.dark:focus {
  box-shadow: 0 0 0 1px var(--color-white);
}

.Button.stretch {
  width: unset;
}
